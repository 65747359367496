import React from 'react'
import SEO from 'components/SEO'
import PublicationPublishingContent from 'components/PublicationPublishing'

const PublicationPublishing = () => {
  return (
    <>
      <SEO title="Publication and Publishing" />
      <PublicationPublishingContent />
    </>
  )
}

export default PublicationPublishing
