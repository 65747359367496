import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { usePageIconsImages } from 'hooks/usePageIconsImagesHook'

import Intro from 'elements/Intro'
import BookOne from 'images/books-images/bakota-book-01.png'
import BookTwo from 'images/books-images/bakota-book-02.png'
import BookThree from 'images/books-images/pseta-01.jpg'
import BookFour from 'images/books-images/svila-01.jpg'
import BookFive from 'images/books-images/chyug.png'
import { GatsbyImage } from 'gatsby-plugin-image'

const PublicationPublishingContent = () => {
  const {
    publicIcon: {
      childImageSharp: {
        gatsbyImageData: {
          images: {
            fallback: { src }
          }
        }
      }
    }
  } = usePageIconsImages()

  const {
    rawData: { edges: booksFeatured }
  } = useStaticQuery(query)
  return (
    <section className="py-16">
      <div className="container mx-auto px-8">
        <Intro pageTitle="Publication and Publishing" imgSrc={src} />
        <div className="mt-14 mb-20">
          <h2 className="text-4xl mb-2 font-bold">Publishing</h2>
          <span className="text-sm text-vividBlue font-bold">
            (selection of books published by “MATE publishing” and ZSEM in Croatia)
          </span>
          {booksFeatured.length &&
            booksFeatured.map(({ node: { id, title_hr, title, author, description, bookImg } }) => {
              return (
                <div
                  className="flex flex-col items-center md:flex-row md:items-start mb-16 mt-14"
                  key={`${id}-${title}`}
                >
                  <div className="w-32 flex flex-shrink-0 shadow-news-card mb-4 md:mb-0">
                    <GatsbyImage image={bookImg.childImageSharp.gatsbyImageData} alt={title} quality="100" />
                  </div>
                  <div className="flex flex-col ml-6 max-w-600px">
                    {title_hr ? <h2 className="text-sm font-bold text-vividBlue">{title_hr}</h2> : null}
                    <h2 className="text-sm text-vividBlue">{title}</h2>
                    <span className="text-sm mb-5">{author}</span>
                    <p className="text-xs">{description}</p>
                  </div>
                </div>
              )
            })}
          <Link
            to="/publishing"
            className="flex bg-white py-2 my-10 shadow-news-card w-full max-w-982px rounded-lg select-none transition-all duration-300 hover:shadow-news-card-strong cursor-pointer border-transparent border-2 hover:border-vividBlue z-1 relative"
          >
            <div className="flex flex-col justify-between text-sm bg-white px-5 my-3 rounded-lg">
              <span className="text-sm text-vividBlue font-bold">Full list of published material</span>
            </div>
          </Link>
          <hr style={{ height: '2px' }} className="my-28 bg-vividBlue opacity-50" />
          <div className="mt-14 mb-8">
            <h2 className="text-4xl mb-2 font-bold">Publications</h2>
            <span className="text-sm text-vividBlue font-bold">
              (Books, collections of papers and editorial books of the CroAsia Institute’s scholars)
            </span>
            <ul className="text-sm mt-8">
              <li className="flex items-center mb-2">
                <div className="w-28">
                  <img
                    src={BookFive}
                    alt="Revolucionari, revizionisti, dogmatičari, psi i luđaci: Kina i Jugoslavija
                  od 1948. do 1971."
                  />
                </div>
                <span className="ml-10">
                  • Stopić, Zvonimir, Ramšak, Jure, Liang Zhanjun, Pirjevec, Jože (2023). China, Yugoslavia, and the
                  Socialist Worldmaking: Convergences and Divergences. Koper: Anales ZRS.
                </span>
              </li>
              <li className="flex items-center mb-2">
                <div className="w-28">
                  <img
                    src={BookThree}
                    alt="Revolucionari, revizionisti, dogmatičari, psi i luđaci: Kina i Jugoslavija
                  od 1948. do 1971."
                  />
                </div>
                <span className="ml-10">
                  • Stopić, Zvonimir (2022.). Revolucionari, revizionisti, dogmatičari, psi i luđaci: Kina i Jugoslavija
                  od 1948. do 1971. [Revolutionaries, Revisionists, Dogmatists, Dogs and Madmen: China and Yugoslavia
                  from 1948 until 1971]. Zagreb: Srednja Europa.
                </span>
              </li>

              <li className="flex items-center mb-2">
                <div className="w-28">
                  <img
                    src={BookFour}
                    alt="Svila, zmajevi i papir: Kineska civilizacija, kultura,
                  arheologija i povijest"
                  />
                </div>
                <span className="ml-10">
                  • Stopić, Zvonimir, Đurđević, Goran (2021.). Svila, zmajevi i papir: Kineska civilizacija, kultura,
                  arheologija i povijest [Silk, Dragons and Paper: Chinese Civilization, Culture and History]. Zagreb:
                  Alfa.
                </span>
              </li>
              <li className="flex items-center mb-2">
                <div className="w-28">
                  <img
                    src={BookOne}
                    alt="Social transformation of Yugoslavia and
                beyond"
                  />
                </div>
                <span className="ml-10">
                  • 白伊维 [Ivica Bakota]：《前南地区国家社会转向及社会发展研究》 [Social transformation of Yugoslavia
                  and beyond] 当代世界出版社2019年.
                </span>
              </li>
              <li className="flex items-center">
                <div className="w-28">
                  <img src={BookTwo} alt="The Cold War and the transformation of Socialism" />
                </div>
                <span className="ml-10">
                  • 白伊维 [Ivica Bakota]：《第八卷：南斯拉夫》[Vol. 8: Yugoslavia]; 沈志华 [Shen
                  Zhihua]：《冷战与社会制度转型》[The Cold War and the transformation of Socialism],
                  上海人民出版社2019年.
                </span>
              </li>
            </ul>
          </div>
          <hr style={{ height: '2px' }} className="my-7 bg-vividBlue opacity-50" />
          <div className="mt-14 mb-8">
            <h2 className="text-4xl mb-2 font-bold">Papers</h2>
            <span className="text-sm text-vividBlue font-bold">(CroAsia Institute’s scholars)</span>
            <ul className="list-disc mt-7 text-sm">
              <li className="ml-4 mb-4">
                Stopić, Z., Niebhur, R., Pickus, D. (2020). Toward Nonalignment: The Improbable and Fateful Intersection
                of Yugoslavia and China in the Early Cold War, 1948–1951. in: Journal of Balkan and Near Eastern Studies
                (Taylor&Francis, DOI: 10.1080/19448953.2020.1867811), January 2021.
              </li>
              <li className="ml-4 mb-4">
                Bakota, I. (2020). Diamond stage in Sino-Croatian relations, Croatian International Relations Review
                (CIRR), XXVI(86).
              </li>
              <li className="ml-4 mb-4">
                Stopić, Z. (2020). Croatia and the Chinese “17+1” cooperation framework. Croatian International
                Relations Review (CIRR), XXVI(86), 130-154.
              </li>
              <li className="ml-4 mb-4">
                Stopić, Z., Đurđević G. (2020). No escape from Balkan? The ‘Balkans’ in the contemporary Croatian
                scientific thought. Southeastern Europe Studies (巴尔干研究), I(1), 162-190.
              </li>
              <li className="ml-4 mb-4">
                Bakota, I. (2019). A new stage in Sino-Croatian relations. China International Studies, 6.
              </li>
              <li className="ml-4 mb-4">
                Stopić, Z., Li Yunxiao (李云霄) (2019). Confusion between the communists: Yugoslavia, China and the
                Cominform’s 1948 resolution. In: Jakovina, T., Previšić, M. (eds.) The Tito-Stalin Split 70 Years After.
                Zagreb-Ljubljana.
              </li>
              <li className="ml-4 mb-4">
                Stopić, Z. (2019). Četiri etape kineske kritike Jugoslavije [Four stages of the Chinese Criticisms of
                Yugoslavia]. Historijski zbornik [Historical Journal; Croatia], LXXII(2), 357-372.
              </li>
              <li className="ml-4 mb-4">
                Silić, M., Njavro, M., Silić, D., Oblaković, G. (2018.) Health Belief Model and Organizational Employee
                Computer Abuse. HCI in Business, Government, and Organizations: Lecture Notes in Computer Science.
              </li>
              <li className="ml-4 mb-4">
                Bakota, I. (2017). Political Economy of the Western Balkans and Chinese Investment Initiatives. CASS,
                Beijing.
              </li>
              <li className="ml-4 mb-4">
                Bakota, I. (2017). Chinese Foreign Policy in the Western Balkans: An Assessment of 16+1 / 7(8) +11+1+
                (n) / (1-1) + (1-1) + n Approaches. CASS, Shanghai.
              </li>
              <li className="ml-4 mb-4">
                Silić, M., Njavro, M., Oblaković, G. (2017). Understanding Color Risk Appropriateness: Influence of
                Color on a User’s Decision to Comply with the IT Security Policy—Evidence from the U.S. and India. In:
                Business, Government and Organizations. New York: Springer International Publishing.
              </li>
              <li className="ml-4 mb-4">
                Stopić, Z. (2017). Pisani odnosi s NR Kinom: Što su, kako i kada Kinezi pisali o Jugoslaviji i
                Hrvatskoj. [Publishing relations with the PR China: What, how and when did the Chinese write about
                Yugoslavia and Croatia]. Historijski zbornik [Historical Journal; Croatia], LXX(1), 137-157.
              </li>
              <li className="ml-4 mb-4">
                Njavro, M., Posedel, P., Vizek, M. (2015). Regime Switching Behaviour of Real Estate and Equity Prices
                in Emerging Countries. Prague Economic Papers.
              </li>
              <li className="ml-4 mb-4">
                Stopić, Z. (2015). Prikaz znanstvenih istraživanja o Drugome svjetskom ratu u Kini [Review of World War
                II Research in the People’s Republic of China]. Historijski zbornik [Historical Journal; Croatia],
                LXVIII(1), 143-164.
              </li>
              <li className="ml-4 mb-4">
                白伊维 [Ivica Bakota]：《南斯拉夫档案介绍》[Yugoslavia Archives
                Introduction]《冷战研究》期15第3期2014年.
              </li>
              <li className="ml-4 mb-4">
                白伊维 [Ivica Bakota]：《1968年苏侵捷克后中南和解的进程》[The Process of Reconciliation between China
                and Yugoslavia after the Soviet Union’s Invasion to the Czech Republic in
                1968]《北大国关青年期》第7期2013年.
              </li>
              <li className="ml-4 mb-4">
                Podobnik, B., Horvatić, D., Njavro, Đ., Njavro, M., Stanley, H. E. (2012). Scaling of Growth Rate
                Volatility for Six Macroeconomic Variables. Contemporary Economics.
              </li>
              <li className="ml-4 mb-4">
                Podobnik, B., Horvatić, Davor; Petersen, M. A., Njavro, M., Stanley, H. E. (2009.). Common scaling
                behaviour in finance and macroeconomics. European Physical Journal B.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PublicationPublishingContent

const query = graphql`
  query useFeaturedBooks {
    rawData: allBooksJson(filter: { id: { in: ["18", "17", "16", "1", "2"] } }, sort: { fields: featured }) {
      edges {
        node {
          id
          featured
          title
          title_hr
          author
          description
          bookImg {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
    }
  }
`
